/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { upperCase } from 'lodash';
import { AccreditationEditListModel } from 'src/types/AccreditationEditList';
import { appRoutePaths } from '../../services/route.service';
import { AccreditationDashboardModel } from '../../types/AccreditationDashboardModel';
import { isEmptyObject } from '../../utilities/helperUtilities';
import type { RootState } from '../store';
import {
  fetchAccreditationEditItemsForCategory,
  fetchAccreditationEditsCount,
} from '../thunks/accreditationThunks';

interface AccreditationState {
  loading: boolean;
  dashboardItems: AccreditationDashboardModel[];
  categoryEdits: AccreditationEditListModel;
}
export const mapDomain = (domain: string): string => {
  if (domain == 'school') {
    return appRoutePaths.AccreditationEdits(domain);
  }
  if (domain == 'lea') {
    return appRoutePaths.AccreditationEdits(domain);
  }
  if (domain == 'personnel') {
    return appRoutePaths.AccreditationEdits(domain);
  }
  return '';
};

export const defaultAccreditationDashboardItems: AccreditationDashboardModel[] =
  [];
export const defaultAccreditationEditList: AccreditationEditListModel = {};

export const initialState: AccreditationState = {
  loading: false,
  dashboardItems: [],
  categoryEdits: defaultAccreditationEditList,
};

export const accreditationSlice = createSlice({
  name: 'accreditation',
  initialState,
  reducers: {
    setEditCountSorted: (state) => {
      const sortedDashboardItems = state.dashboardItems;
      const elementIndex = sortedDashboardItems?.findIndex(
        (eachItem) => upperCase(eachItem.domain) === 'LEA'
      );
      if (elementIndex > 0) {
        const element = sortedDashboardItems?.splice(
          elementIndex,
          elementIndex
        )[0];
        sortedDashboardItems?.splice(0, 0, element);
      }
      state.dashboardItems = sortedDashboardItems;
    },
  },
  extraReducers: (builder) => {
    // fetch edit counts
    builder.addCase(fetchAccreditationEditsCount.pending, (state) => {
      state.dashboardItems = defaultAccreditationDashboardItems;
      state.loading = true;
    });
    builder.addCase(fetchAccreditationEditsCount.fulfilled, (state, action) => {
      if (!isEmptyObject(action.payload)) {
        state.dashboardItems = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(fetchAccreditationEditsCount.rejected, (state, action) => {
      if (!action.meta.aborted) {
        state.loading = false;
      }
    });

    // fetch edit list by category
    builder.addCase(fetchAccreditationEditItemsForCategory.pending, (state) => {
      state.categoryEdits = defaultAccreditationEditList;
      state.loading = true;
    });
    builder.addCase(
      fetchAccreditationEditItemsForCategory.fulfilled,
      (state, action) => {
        if (!isEmptyObject(action.payload)) {
          state.categoryEdits = action.payload;
        }

        state.loading = false;
      }
    );
    builder.addCase(
      fetchAccreditationEditItemsForCategory.rejected,
      (state, action) => {
        if (!action.meta.aborted) {
        state.loading = false;
        }
      }
    );
  },
});

// export reducers
export const { setEditCountSorted } = accreditationSlice.actions;

export const selectAccreditationLoading = (state: RootState): boolean =>
  state.accreditation.loading;
export const selectAccreditationDashboardItems = (
  state: RootState
): AccreditationDashboardModel[] => state.accreditation.dashboardItems;
export const selectAccreditationEditItems = (
  state: RootState
): AccreditationEditListModel => state.accreditation.categoryEdits;

export default accreditationSlice.reducer;
